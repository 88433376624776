/* START old implementation */
/*var googletag = googletag || {};
googletag.cmd = googletag.cmd || [];

if ('jsVars' in window) {
  if (jsVars.ads.dfp.id) {
    window.gadsId = jsVars.ads.dfp.id;
  }
}*/

var touchesPageX = 0,
  touchesPageY = 0,
  headerStick = 0,
  headerHide = 0,
  footerStick = 0;
/* END old implementation */
const $body = $(document.body);
const edtn = $body.data('edtn');
// disable scroll restoration
// if (history.scrollRestoration && (edtn !== 'ru' && edtn !== 'uk' && edtn !== 'es')) {
//   history.scrollRestoration = 'manual';
// }

import 'scrollingelement';
import $ from 'jquery';
export { $ };
window.$ = $;
window.jQuery = $;

import device from 'current-device';

import svg4everybody from 'svg4everybody';

import 'jquery-form';
// import 'jquery-validation';
import './jquery.scrollstop';

// import { formValidateConfig } from './jquery-validate-config';

import 'jquery.cookie';

import { scaleFix } from './utils/scale-fix';

import { cmpTrayAlert } from './cmp-tray-alert';
export { cmpTrayAlert };
window.cmpTrayAlert = cmpTrayAlert;

import { cmpDialog } from './cmp-dialog';
window.cmpDialog = cmpDialog;

import { cmpBubbles } from './cmp-bubbles';

import { cmpButtons } from './cmp-buttons/cmp-buttons';
import { cmpButtonsToggle } from './cmp-buttons/cmp-buttons-toggle';
import { cmpButtonsUtils } from './cmp-buttons/cmp-buttons-utils';
import { cmpButtonsCurrent } from './cmp-buttons/cmp-buttons-current';
export { cmpButtonsUtils };

import { formsConfig } from './forms';
export { formsConfig };

import 'jquery.dotdotdot';
import { oneTrust } from './onetrust';
import { throttle } from 'throttle-debounce';
import { goToUrl, banners, floating_share_box, stickyContainer, pageMessages, showHideBtn, translate, bigImgLazyload, bannerLazyload, adSkins, toggleMenuDrawerWithAnimation, setCookie, getCookie } from './utils/fn';
import { exportGetScript, exportGetScriptNew } from './utils/fn';
import { getApi } from './utils/api-loader';

// export { banners };
// window.banners = banners;

// import countsBox from '../../components/counts-box/counts-box';
// import commentsCount from './comments/comments-count';
// import ShareBoxDropdown from '../../components/shares-box/share-box-dropdown';

import buttons from './utils/buttons';
import '../../components/waves/waves';
// import Dotdotdot from 'dotdotdot-js';

const headerButtons = {
  mainMenuToggle(data, element) {
    cmpButtonsUtils.buttons.toggleBox.click.call(
      this,
      data,
      element,
      'mainMenuToggle'
    );

    toggleMenuDrawerWithAnimation(data.toggle);
  },
  mainMenuToggleClose(data, element) {
    toggleMenuDrawerWithAnimation(false);
  },
};

export const vr = {
  listMorePageOffset: 500,
};

// Detect that user has enabled cookie in browser
window.isCookieSupported = false;
const testCookie = getCookie('cookie_supported');
if (!testCookie) {
  setCookie('cookie_supported', 1, 1);
}
window.isCookieSupported = +getCookie('cookie_supported') === 1;

var buttonsFn = $.extend(buttons, headerButtons);
export { buttonsFn };

// import { fn } from './utils/fn';
// export { fn };
// window.fn = fn;
window.adSkins = adSkins;
window.getScript = exportGetScript;
window.getScriptNew = exportGetScriptNew;
// window.adSkinsTest = fn.adSkinsTest.bind(fn);

if (edtn === 'de' || edtn === 'ev_de') {
  stickyContainer();

  // oneTrust.initGTMforCPusers($('.touch'));
}

const hasTouch = (window.isTouch = !!('ontouchstart' in window));
const isDesktop =
  (window.innerWidth || document.documentElement.clientWidth) >= 1024 &&
  device.type == 'desktop';

const touchClassName = isDesktop ? 'no-touch' : hasTouch ? 'touch' : 'no-touch';

document.documentElement.className = touchClassName;

window.preventScrollFlag = false;

const commonDotDotSelectors = '.m1-top-slider-items .info .middle h3, .m1-top-slider-items .info .middle .description';

export const currentPage = {};
$(function() {
  /* START old implementation */
  var tempScrollTop = 0,
    scrollDir = 0,
    $layout_header_spacer = $('#layout_header_spacer');

  var _mobileMQMaxWidth = 768,
    menusItemsTimeout = null,
    menusItemsResize = false,
    docCurrentWidth = 0;

  var $cookie_accept = $('#cookie_accept_button'),
    is_cookie_accept = $cookie_accept.length;

  var scroll_stop_timeout = null,
    scroll_stop_count = 0;

  const $body = $(document.body);

  $(window)
    .on('scroll.header', function() {
      if (scroll_stop_timeout !== null) {
        clearTimeout(scroll_stop_timeout);
      }
      scroll_stop_timeout = setTimeout(function() {
        scroll_stop_count++;
      }, 150);

      if (is_cookie_accept && scroll_stop_count == 7) {
        is_cookie_accept = false;
        $cookie_accept.trigger('click');
      }
      var scrollTop = window.scrollY || window.pageYOffset || 0;

      if ($layout_header_spacer.length != 0) {
        var topOffsetVal = $layout_header_spacer.offset().top;
      }

      if (tempScrollTop > scrollTop) {
        if (scrollDir == 0) {
          if (
            scrollTop < 0 ||
            document.documentElement.scrollHeight <
              scrollTop + document.documentElement.clientHeight
          ) {
            $('body').removeClass('footerStick');
            footerStick == 0;
          } else {
            if (footerStick == 0) {
              $('body').addClass('footerStick');
            }
            footerStick == 1;
          }
        }
        scrollDir = 1;
      } else if (tempScrollTop < scrollTop) {
        if (scrollDir == 1) {
          $('body').removeClass('footerStick');
          footerStick == 0;
        }
        scrollDir = 0;
      }

      if (
        document.documentElement.scrollHeight -
          document.documentElement.clientHeight <
        topOffsetVal
      ) {
        if (headerStick == 1) {
          $('body').removeClass('headerStick headerStick-hide');
        }
        return;
      }

      if (scrollTop > topOffsetVal) {
        if (headerStick == 0) {
          $('body').addClass('headerStick');
        }
        headerStick = 1;
        if (
          headerStick == 1 &&
          headerHide == 0 &&
          scrollTop > topOffsetVal + 60
        ) {
          $('body')
            .stop(true, true)
            .delay(500)
            .show(1, function() {
              $('body').addClass('headerStick-hide');
            });
          headerHide = 1;
        }
      } else {
        if (headerStick == 1) {
          $('body').removeClass('headerStick headerStick-hide');
        }
        headerStick = 0;
        headerHide = 0;
      }
      tempScrollTop = scrollTop;
    })
    .trigger('scroll.header');

  // $(document.body).on('click', '.m1-drawer', function(event) {
  //   event.stopPropagation();
  // });

  $(document.body).on('click', '.m1-navigation-main', function(event) {
    event.stopPropagation();
  });

  $(document.body).on('click', '.m1-side-logo', function(event) {
    event.stopPropagation();
  });

  $(document.body).on('click', '.m1-msn-menus-box', function(event) {
    event.stopPropagation();
  });

  $(document.body).on('click', '.m1-footer', function(event) {
    event.stopPropagation();
  });

  $(document.body).on('click', '.m1-info-menu li:not(.one-trust)', function(
    event
  ) {
    event.stopPropagation();
  });

  $('.m1-search-form').on('submit', function() {
    var $serach = $(this).find('.m1-search-form-text'),
      val = $.trim($serach.val());

    if (val && val.length > 0) {
      $serach.val(val);
      return true;
    } else {
      return false;
    }
  });
  $('.m1-search-form-button-animate:not(.active)').on('click', function(e) {
    $('#search_input').focus();
  });

  if ('localStorage' in window && localStorage['cmpTrayAlertMessage']) {
    cmpTrayAlert.show(
      localStorage['cmpTrayAlertMessage'],
      localStorage['cmpTrayAlertType'] || null,
      localStorage['cmpTrayAlertDelay'] || null
    );
    localStorage.removeItem('cmpTrayAlertMessage');
    localStorage.removeItem('cmpTrayAlertType');
    localStorage.removeItem('cmpTrayAlertDelay');
  }

  getApi().then((api) => {
    api.colorScheme();
  });

  // if (edtn === 'it') {
  //   let closeButton4Ads = document.querySelector('.footer-ads-wrapper_close-button');
  //   let adhesionBlock = document.querySelector('.footer-ads-wrapper');
  //   if (closeButton4Ads && adhesionBlock) {
  //     closeButton4Ads.addEventListener('click', function(){
  //       adhesionBlock.remove();
  //     });
  //   }
  // }

  //   bigImgLazyload();    AM-13053
  // hybridLazyLoad();
  bannerLazyload();

  const filterContainer = document.querySelector('.filtersBox');
  if (filterContainer) showHideBtn(filterContainer);


  var bannerTimeout = null;
  $(window).on('resize', function() {
    clearTimeout(bannerTimeout);
    bannerTimeout = setTimeout(function() {
      banners();
    }, 500);
  });

  $body.on(
    'm1-footer-messages',
    pageMessages.bind(
      this,
      window.m1FooterMessages,
      '.m1-footer-messages',
      'm1-footer-message',
      'footerMessageAccept'
    )
  );
  setTimeout(_ => {
    $body.trigger('m1-footer-messages');
  }, 1000);

  var $root = $('.root'),
    $detailPage = false;

  if ($root.is('#page_index_articles_detail')) {
    $detailPage = true;
  }

  if (device.type !== 'mobile' && !$detailPage) {
    setTimeout(floating_share_box, 5000);
  }
  oneTrust.initShareBlock($('.content-area-box'));

  var $submodelBody = $('.submodel-trims_body'),
    $submodelRows = $submodelBody.find('tr'),
    $showMore = $('#showMore'),
    showMoreToggle = false,
    showCountRows = 5;

  $submodelRows
    .hide()
    .slice(0, showCountRows)
    .show();
  $showMore
    .find('span')
    .last()
    .hide();
  $showMore.on('click', function() {
    showMoreToggle = !showMoreToggle;
    if (showMoreToggle) {
      $(this)
        .find('span')
        .show()
        .first()
        .hide();
      $submodelRows.show();
    } else {
      $(this)
        .find('span')
        .show()
        .last()
        .hide();
      $submodelRows.slice(showCountRows).hide();
    }
  });

  /* END old implementation */

  // $.cookie('m1_touch', touchClassName, { path: '/' });
  setCookie('m1_touch', touchClassName, false);


  scaleFix();

  svg4everybody({
    //polyfill: true // polyfill <use> elements for External Content
  });

  const $currentPage = $('[data-role="page"]');
  const $window = $(window);

  $(document.body).removeClass('nojs');

  cmpTrayAlert.init({ insert: 1 });
  cmpDialog.init({
    buttonOk: translate('button_ok', 'Ok'),
    buttonCancel: translate('button_cancel', 'Cancel'),
    buttonClose: translate('button_close', 'Close'),
  });
  cmpButtons.init({
    buttons: buttonsFn,
    error: function(data, element, response, textStatus, jqXHR) {
      cmpTrayAlert.show(
        jqXHR.responseJSON &&
        jqXHR.responseJSON.constructor === Object &&
        jqXHR.responseJSON.message
          ? jqXHR.responseJSON.message
          : 'Bad response (JSON)',
        'error',
        30
      );
    },
  });

  cmpButtonsToggle.init({});
  cmpButtonsCurrent.init();

  cmpBubbles.init({
    minWidth: 640,
    error: function(data, element, response, textStatus, jqXHR) {
      cmpTrayAlert.show(
        jqXHR.responseJSON &&
        jqXHR.responseJSON.constructor === Object &&
        jqXHR.responseJSON.message
          ? jqXHR.responseJSON.message
          : 'Bad response (JSON)',
        'error',
        30
      );
    },
  });

  // formValidateConfig.init({
  //   forms: formsConfig,
  // });

  var currentPageParams = {};

  if ('pageInit' in currentPage) {
    if (typeof window.Promise === 'function') {
      currentPageParams = currentPage.pageInit(
        $currentPage,
        $currentPage.data(),
        $currentPage.attr('id')
      );
      initCurrentPage(currentPageParams, $currentPage);
    } else {
      // Polyfill for Promises, required to make dynamic imports inside Webpack
      $.getScript('/design/src/js/polyfills/es6-promise.min.js', () => {
        window.ES6Promise.polyfill();
        currentPageParams = currentPage.pageInit(
          $currentPage,
          $currentPage.data(),
          $currentPage.attr('id')
        );
        initCurrentPage(currentPageParams, $currentPage);
      });
    }
  }

  // we need to make sure that event is passive, otherwise we won't be able to prevent it
  document.scrollingElement.addEventListener(
    'touchmove',
    event => {
      if (window.preventScrollFlag) {
        event.preventDefault();
      }
    },
    {
      passive: false,
    }
  );

  $(document.scrollingElement)
    .on('touchstart', function(event) {
      var touches = event.originalEvent.changedTouches[0];
      touchesPageX = touches.pageX;
      touchesPageY = touches.pageY;
    })
    .on('touchend', function(event) {
      window.preventScrollFlag = false;
    });

  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  banners();

  //fn.banners();
  // advSkins.init();
  var bannerTimeout = null;


  $('.m1-navigation-main').on('click', '.dropdown-toggle', function () {
    var $this = $(this);
    $this.toggleClass('active');
    $this.parent('.dropdown').next('.dropdown-wrapper').toggle(300);
  });
  $('.m1-drawer-overlay, .m1-drawer-close-button').on('click', function () {
    $('.m1-navigation-main .dropdown-toggle').removeClass('active');
    $('.m1-navigation-main .dropdown-wrapper').hide(300);
  });

  var $filterBox = $('#filters_box');
  if ($filterBox.length > 0) {
    const filterListHeight = $('.filter-list').prop('scrollHeight');
    const currentURL = window.location.href;

    if(window.innerWidth <= 1023 && (currentURL.includes('reviews/') || currentURL.includes('features/'))) {
        const toggleButton = $('[data-value="toggleBox"]');
        const filterList = $('.filter-list');
        filterList.css('max-height', 'none');
        toggleButton.remove();
        return;
    }

    if (filterListHeight <= 40) {
      const toggleButton = $('[data-value="toggleBox"]');
      const filterList = $('.filter-list');
      filterList.css('max-height', 'none');
      toggleButton.remove();
    }
  }
});

function initCurrentPage(params, $page) {
  const $window = $(window);
  const $body = $(document.body);

  const dotdotdotSelectors =
    commonDotDotSelectors +
    (params && params.dotdotdotSelectors
      ? ', ' + params.dotdotdotSelectors
      : '');
  $page.find(dotdotdotSelectors).dotdotdot({
    height: 'watch',
    truncate: 'word',
  });
  // cpass
  let cpAuthhint = getCookie('_cpauthhint');
  if (cpAuthhint && (edtn == 'us' || edtn == 'fr' || edtn == 'ar')) {
    console.log('load comments fo cpass user! browse');
    oneTrust.initCommentsforCPusers($('.root'));
  }
  // for (let item of document.querySelectorAll(dotdotdotSelectors)) {
  //   new Dotdotdot(item, {
  //     truncate: 'word',
  //     height: 'watch'
  //   });
  // }
  

  // console.log($page, params, params.dotdotdotSelectors);
  // let wrappers = document.querySelectorAll('.info .text-box, .m1-top-slider-items .info .middle h3, .m1-top-slider-items .info .middle .description, .latest__articles-list-item-title');
  // console.log(wrappers);
  // let options = {
  //   truncate: 'word',
  //   height: 'watch'
  // };
  // for (let wrapper of wrappers) {
  //   new Dotdotdot( wrapper, options );
  // }

  if (!params || params.constructor !== Object) return;
}

export const initNewSection = ($page, params) => {
  const $body = $(document.body);

  const dotdotdotSelectors =
    commonDotDotSelectors +
    (params && params.dotdotdotSelectors
      ? ', ' + params.dotdotdotSelectors
      : '');
  $page.find(dotdotdotSelectors).dotdotdot({
    height: 'watch',
    truncate: 'word',
  });

  // cpass
  let cpAuthhint = getCookie('_cpauthhint');
  if (cpAuthhint && (edtn == 'us' || edtn == 'fr' || edtn == 'ar')) {
    console.log('load comments fo cpass user! browse');
    oneTrust.initCommentsforCPusers($('.root'));
  }

  if (!params || params.constructor !== Object) return;
};

window.translate = translate;
window.goToUrl = goToUrl;